<template>
  <div class="scenery">
    <el-container>
      <el-main>
        <slideshow :slideshowList="slideshowList"></slideshow>
        <img class="debris" src="../../../static/scenery/debris.png" />
        <div class="scenery-content">
          <div class="content-top" style="width: 65%; margin: 0 auto">
            <div class="bg-image1"></div>
            <div class="bg-image2"></div>
            <div class="top-title">
              <img
                class="home-icon"
                src="../../../static/scenery/home.png"
                alt=""
                style="width: 20; height: 20"
              />
              <span
                style="margin-right: 6px; font-weight: bold"
                @click="Jump('/', 0)"
                >首页</span
              >
              >
              <span style="margin-left: 6px" @click="Jump('/about')"
                >走进条子泥</span
              >
              >
              <span style="margin-left: 6px; color: #509ab1">条子泥风光</span>
            </div>
            <p class="image">精美图片</p>
            <el-tabs
              v-model="activeName"
              @tab-click="handleClick"
              stretch
              style="width: 100%; padding: 0 30px"
            >
              <el-tab-pane
                v-for="item in imageTitleList"
                :key="item.id"
                :label="item.catalogueName"
                :name="item.id"
              >
                <div
                  class="bird-image"
                  v-if="item.id == '402880447ba001ab017ba0096fdb005f'"
                >
                  <div
                    class="brief"
                    @click="handleGo(item.birdType, item.catalogueId)"
                    v-for="item in pictureList"
                    :key="item.id"
                  >
                    <!-- <div class="bird-box">
                                            <p>{{item.picName}}</p>
                                            <span></span>
                                            <img :src="item.picUrl" alt="">
                                        </div> -->
                    <div
                      class="bird-box"
                      :style="{
                        'background-image': 'url(' + item.picUrl + ')',
                      }"
                    >
                      <p>{{ item.picName }}</p>
                      <p v-if="item.id == '402880447ba001ab017ba0096fdb005f'">
                        {{ item.birdType.split("|")[3] }}
                      </p>
                      <!-- <span></span> -->
                      <img
                        src="~static/scenery/brid-box.png"
                        style="opacity: 0.7"
                        alt=""
                      />
                    </div>
                    <p class="author">作者：{{ item.picContent }}</p>
                  </div>
                  <!-- <div class="brief" @click="handleGo">
                                        <div class="bird-box">
                                            <span></span>
                                            <img src="../../../static/scenery/bird1.png" alt="">
                                        </div>
                                    </div>-->
                </div>
                <div class="scenic-cultural" v-else>
                  <div
                    class="tzn-brief"
                    v-for="item in pictureList"
                    :key="item.id"
                  >
                    <div
                      class="bird-box"
                      :style="{
                        'background-image': 'url(' + item.picUrl + ')',
                      }"
                    >
                      <!-- <span></span> -->
                      <img
                        src="~static/scenery/brid-box.png"
                        style="opacity: 0.7"
                        alt=""
                      />
                    </div>
                    <p class="author">作者：{{ item.picContent }}</p>
                  </div>
                </div>
                <div class="ckeckmore" @click="handleMore(item.id)">
                  <p class="more-text">查看更多</p>
                  <span></span>
                </div>
              </el-tab-pane>
            </el-tabs>
            <!-- <div class="ckeckmore" @click="Jump('/sceneryMore')">
                            <p class="more-text" >查看更多</p>
                            <span></span>
                        </div> -->
          </div>
          <div class="content-bottom" style="width: 60%; margin: 0 auto">
            <div class="bg-image3"></div>
            <div class="bg-image4"></div>
            <div class="bg-image5"></div>
            <p class="image">精彩视频</p>
            <div class="movie">
              <div
                class="video"
                v-for="(item, index) in videoList"
                :key="index"
              >
                <span class="video-box"></span>
                <video
                  style="object-fit: fill"
                  controls="controls"
                  :src="item.videoPath"
                  type="video/mp4"
                ></video>
              </div>
              <!-- <div class="video">
                                <span class="video-box"></span>
                                <video
                                    style="object-fit: fill;"
                                    width="354"
                                    height="355"
                                    controls="controls"
                                    src="http://117.80.146.163:18080/tznmh/tzn_img/tiaozini.mp4"
                                    type="video/mp4"
                                ></video>
                            </div> -->
            </div>
            <div class="ckeckmore" @click="Jump('/videoMore')">
              <p class="more-text">查看更多</p>
              <span></span>
            </div>
          </div>
        </div>
        <leftFloat :floatList="floatList" />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { slideshow } from "@/api/test/test";
import { tzn_tplbAPI, tzn_tpxqAPI, tzn_jmspAPI } from "@/api/test/scenery";
export default {
  name: "Scenery",
  components: {
    leftFloat: () => import("../../components/leftFloat.vue"),
  },
  data() {
    return {
      floatList: {
        list: [
          { name: "条子泥印象", url: "/about" },
          { name: "条子泥风光", url: "flush" },
          { name: "条子泥动态", url: "/dynamic" },
        ],
        title: "走进条子泥",
      },
      slideshowList: [],
      activeName: "402880447ba001ab017ba0096fdb005f",
      imageTitleList: [],
      pictureList: [],
      videoList: [],
    };
  },
  created() {},
  mounted() {
    //头部轮播图
    slideshow().then((res) => {
      this.slideshowList = res;
      // console.log(this.slideshowList);
    });
    //图片目录
    this.$nextTick(() => {
      tzn_tplbAPI().then((res) => {
        this.imageTitleList = res;
        console.log(this.imageTitleList, "-----");
      });
    });

    //精美视频
    tzn_jmspAPI({
      isUse: 1,
      size: 6,
      page: 0,
    }).then((res) => {
      this.videoList = res.data;
    });
    //精美图片
    this.handleClick();
  },
  methods: {
    //鸟类图片跳转
    handleGo(birdType, catalogueId) {
      this.$router.push({
        path: "/scenerydetails",
        query: {
          birdType: birdType.split("|")[2],
          catalogueId,
        },
      });
    },
    //teb切换
    handleClick() {
      if (this.activeName == "402880447ba001ab017ba0096fdb005f") {
        this.bridTotalData();
      } else {
        this.otherTotalData();
      }
    },
    bridTotalData() {
      tzn_tpxqAPI({
        isUse: 1,
        catalogueId: this.activeName,
        size: 8,
        page: 0,
      }).then((res) => {
        this.pictureList = res.data;
      });
    },
    otherTotalData() {
      tzn_tpxqAPI({
        isUse: 1,
        catalogueId: this.activeName,
        size: 6,
        page: 0,
      }).then((res) => {
        this.pictureList = res.data;
      });
    },
    //左边列表跳换
    handleJump(url) {
      this.$router.push(url);
    },
    handleMore(id) {
      this.$router.push({
        path: "/sceneryMore",
        query: {
          id,
        },
      });
    },
    //数据格式化
    decodeHTML(str) {
      var s = "";
      if (!str || str.length == 0) return "";
      s = str.replace(/&amp;/g, "&");
      s = s.replace(/&lt;/g, "<");
      s = s.replace(/&gt;/g, ">");
      s = s.replace(/&nbsp;/g, " ");
      s = s.replace(/&#39;/g, "'");
      s = s.replace(/&quot;/g, '"');
      return s;
    },
  },
};
</script>


<style scoped>
.scenery {
  background: url("../../../static/scenery/backgd.png");
}
.el-main {
  padding: 0;
  overflow-x: hidden;
}

.debris {
  width: 100%;
  margin-top: -157px;
  z-index: 8;
  background: transparent;
  position: relative;
}
.content-top {
  position: relative;
}
.content-bottom {
  position: relative;
}
.bg-image1 {
  width: 390px;
  height: 292px;
  background-image: url("../../../static/scenery/dfbh.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  right: -32%;
  top: 7%;
}
.bg-image2 {
  width: 428px;
  height: 353px;
  background-image: url("../../../static/scenery/hly.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: -33%;
  bottom: 7%;
}
.bg-image3 {
  width: 428px;
  height: 353px;
  background-image: url("../../../static/scenery/hlz.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  right: -33%;
  top: -18%;
}
.bg-image4 {
  width: 428px;
  height: 353px;
  background-image: url("../../../static/scenery/dfbh.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  right: -33%;
  bottom: 20%;
}
.bg-image5 {
  width: 428px;
  height: 353px;
  background-image: url("../../../static/scenery/hly.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: -33%;
  bottom: 10%;
}
.top-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 92px;
  background-image: url("../../../static/scenery/title.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: left;
  line-height: 92px;
  font-size: 24px;
  letter-spacing: 5px;
}
.home-icon {
  width: 20px;
  height: 20px;
  margin: 0 16px 0 53px;
}
.top-title > span:hover {
  cursor: pointer;
  color: #509ab1;
}
.image {
  width: 450px;
  height: 250px;
  background-image: url("../../../static/scenery/img-box.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
  line-height: 250px;
  margin: 55px auto;
  letter-spacing: 10px;
  font-size: 42px;
  color: #509ab1;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.28);
}
/deep/ .el-tabs__item {
  background-image: url("../../../static/scenery/bgbox.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #f6d397;
  margin: 0 15px;
  padding: 0;
  height: 60px;
  line-height: 60px;
  font-size: 28px;
}
/deep/ .el-tabs__item.is-active {
  background-image: url("../../../static/scenery/bgbox-select.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #509ab1;
}
/deep/ .el-tabs__active-bar {
  display: none;
}
/deep/ .el-tabs__nav-wrap::after {
  display: none;
}
/deep/ .el-tabs__nav.is-stretch {
  min-width: inherit;
  width: 100%;
  box-sizing: border-box;
  padding: 0 10%;
  margin-bottom: 52px;
}
.bird-image,
.scenic-cultural {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.brief {
  margin: 8px;
  width: 23%;
  cursor: pointer;
}
.tzn-brief {
  margin: 8px;
  width: 30%;
  cursor: pointer;
}
.bird-box {
  position: relative;
  background-repeat: no-repeat;
  background-size: 98% 98%;
}
.bird-box > p {
  position: absolute;
  top: 21%;
  left: 10%;
  width: 30px;
  font-size: 28px;
  font-family: "tzn-font";
  color: #fff;
  line-height: 40px;
}
.bird-box > img {
  display: block;
  width: 100%;
  height: auto;
}
.author {
  width: 100%;
  height: 70px;
  font-size: 18px;
  font-weight: bold;
  color: #509ab1;
  line-height: 50px;
  text-align: center;
  background-image: url("../../../static/scenery/img-author.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.ckeckmore {
  width: 201px;
  height: 60px;
  background-image: url("../../../static/scenery/more.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 105px auto 119px;
  cursor: pointer;
}
.more-text {
  font-size: 22px;
  color: #509ab1;
  line-height: 60px;
}
.ckeckmore > span {
  width: 26px;
  height: 7px;
  background-image: url("../../../static/scenery/jiantou.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  right: 10%;
}
/* 样式 */
.img-carousel {
  overflow-y: auto;
  height: 765px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  background-color: #f8f2eb;
}
.img-show {
  position: relative;
  width: 350px;
  height: 351px;
  border-radius: 50%;
}
.img-show > p {
  position: absolute;
  top: 75px;
  left: 55px;
  width: 30px;
  font-size: 30px;
  font-weight: bold;
  color: #ffffff;
}
.border-box {
  width: 100%;
  height: 100%;
  background-image: url("../../../static/scenery/border.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: -3px;
}
.movie {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.video {
  position: relative;
  margin-bottom: 30px;
  width: 500px;
  height: 400px;
}
.video-box {
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background-image: url("../../../static/scenery/video-box.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: -2px;
}
.video > video {
  width: 98%;
  height: 100%;
}
</style>